import React, { useContext } from "react"

import { navigate } from "gatsby"

import { Cloud, Plus } from "akar-icons"
import PropTypes from "prop-types"

import {
  BILLING_ADDRESS,
  SHIPPING_ADDRESS,
} from "../../constants/general-constants"
import { checkoutCreate, checkoutLinesUpdate } from "../../graphql"
import { getListOfAddress } from "../../graphql/checkout"
import { AuthContext, CartContext } from "../../store"
import { getLines } from "../../utils/methods"
import Button from "../common/Button"
import { TYPE_ERROR, toastMessage } from "../common/Toast"
import AddressCard from "./AddressCard"

import "./Checkout.scss"

const Checkout = ({ lines }) => {
  const {
    clearCart,
    checkoutLines,
    checkoutId,
    setSelectedShippingMethod,
    setCheckoutCreateObject,
    setAddress,
    billingAddress: filledBillingAddress,
    shippingAddress: filledShippingAddress,
  } = useContext(CartContext)

  const [isBillingSame, setIsBillingSame] = React.useState(false)
  const [shippingAddress, setShippingAddress] = React.useState(
    filledShippingAddress
  )
  const [billingAddress, setBillingAddress] =
    React.useState(filledBillingAddress)

  const [savedAddressList, setSavedAddressList] = React.useState([])

  const [isShowBillingForm, setIsShowBillingForm] = React.useState(false)
  const [isShowShippingForm, setIsShowShippingForm] = React.useState(false)
  const [toggleSelectedShippingAddress, setToggleSelectedShippingAddress] =
    React.useState("")
  const [toggleSelectedBillingAddress, setToggleSelectedBillingAddress] =
    React.useState("")

  const [isAddNewShipping, setIsAddNewShipping] = React.useState(false)
  const [isAddNewBilling, setIsAddNewBilling] = React.useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false)

  const { user, setLoader } = useContext(AuthContext)

  const { email, token } = user

  React.useEffect(() => {
    if (
      filledBillingAddress.city !== "" &&
      filledBillingAddress.firstName !== "" &&
      filledBillingAddress.lastName !== "" &&
      filledBillingAddress.streetAddress1 !== ""
    ) {
      setIsShowBillingForm(true)
    }
    if (
      filledShippingAddress.city !== "" &&
      filledShippingAddress.firstName !== "" &&
      filledShippingAddress.lastName !== "" &&
      filledShippingAddress.streetAddress1 !== ""
    ) {
      setIsShowShippingForm(true)
    }
    if (token) {
      getListOfAddress(token)
        .then((res) => {
          if (res.me === null) {
            toastMessage("Invalid Token", TYPE_ERROR)
          } else if (res.me.addresses.length === 0) {
            setIsAddNewBilling(true)
            setIsAddNewShipping(true)
            setIsShowBillingForm(true)
            setIsShowShippingForm(true)
            setIsBtnDisabled(true)
          }
          setSavedAddressList(res.me.addresses)
        })
        .catch(() => {
          toastMessage("Something Went Wrong", TYPE_ERROR)
        })
    }
  }, [token])
  const formattedLines = getLines(lines)
  const existingCheckoutLines = getLines(
    checkoutLines?.filter(
      (line) => !lines.some((l) => l.variantId === line.variantId)
    )
  )

  const handleShippingChange = (e) => {
    const value =
      e.target.name === "phone" && e.target.value < 0
        ? Math.abs(e.target.value)
        : e.target.value

    setShippingAddress({
      ...shippingAddress,
      country: "IN",
      [e.target.name]: value,
    })
  }

  const handleBillingChange = (e) => {
    const value =
      e.target.name === "phone" && e.target.value < 0
        ? Math.abs(e.target.value)
        : e.target.value

    setBillingAddress({
      ...billingAddress,
      country: "IN",
      [e.target.name]: value,
    })
  }

  const createCheckoutObject = async () => {
    if (isBillingSame) {
      setAddress(shippingAddress, shippingAddress, isAddNewShipping, false)
    } else {
      setAddress(
        shippingAddress,
        billingAddress,
        isAddNewShipping,
        isAddNewBilling
      )
    }

    const data = checkoutId
      ? { checkoutId, lines: [...existingCheckoutLines, ...formattedLines] }
      : {
          email,
          lines: formattedLines,
          shippingAddress,
          billingAddress: isBillingSame ? shippingAddress : billingAddress,
        }

    setLoader(true)
    try {
      const response = checkoutId
        ? await checkoutLinesUpdate(data)
        : await checkoutCreate(data)

      if (
        response?.checkoutCreate?.checkoutErrors?.length === 0 ||
        response?.checkoutLinesUpdate?.checkoutErrors?.length === 0
      ) {
        const checkout = checkoutId
          ? response.checkoutLinesUpdate.checkout
          : response.checkoutCreate.checkout

        setCheckoutCreateObject(checkout)

        if (!checkout.isShippingRequired) {
          const shippingMethod = "none"
          const shippingPrice = 0
          const totalPrice = checkout.totalPrice

          setSelectedShippingMethod({
            shippingMethod,
            shippingPrice,
            totalPrice,
          })
          navigate("/payment")
          setLoader(false)
        } else {
          navigate("/shipping")
          setLoader(false)
        }
      } else {
        setLoader(false)
        const errorObject = response.checkoutCreate?.checkoutErrors
          ? response.checkoutCreate.checkoutErrors[0]
          : response.checkoutLinesUpdate.checkoutErrors[0]
        const isPostalcodeError =
          errorObject.field.toUpperCase() === "POSTALCODE"

        const errorMessage = errorObject?.message
          ? errorObject?.message
          : isPostalcodeError
          ? errorObject.code.split("_").join(" ") +
            " " +
            errorObject.field.toUpperCase()
          : errorObject.code.split("_").join(" ")

        if (errorObject.field === "quantity") {
          clearCart()
          navigate("/")
        }

        toastMessage(errorMessage, TYPE_ERROR)
      }
    } catch (error) {
      setLoader(false)
    }
  }

  const handleClick = () => {
    if (
      shippingAddress.city === "" ||
      shippingAddress.country === "" ||
      shippingAddress.firstName === "" ||
      shippingAddress.lastName === "" ||
      shippingAddress.streetAddress1 === "" ||
      shippingAddress.countryArea === "" ||
      shippingAddress.postalCode === "" ||
      shippingAddress.phone === ""
    ) {
      toastMessage("Please fill all the shipping address details!", TYPE_ERROR)
    } else if (
      billingAddress.city === "" ||
      billingAddress.country === "" ||
      billingAddress.firstName === "" ||
      billingAddress.lastName === "" ||
      billingAddress.streetAddress1 === "" ||
      billingAddress.countryArea === "" ||
      billingAddress.postalCode === "" ||
      billingAddress.phone === ""
    ) {
      toastMessage("Please fill all the billing address details!", TYPE_ERROR)
    } else {
      createCheckoutObject()
    }
  }

  const fillShippingForm = ({
    city,
    firstName,
    lastName,
    streetAddress1,
    streetAddress2,
    countryArea,
    postalCode,
    phone,
    id,
  }) => {
    setShippingAddress({
      city,
      country: "IN",
      firstName,
      lastName,
      streetAddress1,
      streetAddress2,
      countryArea,
      postalCode,
      phone: phone.substring(3, phone.length),
    })
    setIsShowShippingForm(false)
    setToggleSelectedShippingAddress(id)
    setIsBillingSame(false)
  }

  const fillBillingForm = ({
    city,
    firstName,
    lastName,
    streetAddress1,
    streetAddress2,
    countryArea,
    postalCode,
    phone,
    id,
  }) => {
    setBillingAddress({
      city,
      country: "IN",
      firstName,
      lastName,
      streetAddress1,
      streetAddress2,
      countryArea,
      postalCode,
      phone: phone.substring(3, phone.length),
    })
    setIsShowBillingForm(false)
    setToggleSelectedBillingAddress(id)
    setIsBillingSame(false)
  }

  const handleNewShippingAddress = () => {
    setIsShowShippingForm(true)
    setIsAddNewShipping(true)
    setShippingAddress({
      city: "",
      country: "IN",
      countryArea: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      streetAddress1: "",
      streetAddress2: "",
      phone: "",
    })
    setIsBillingSame(false)
    setToggleSelectedShippingAddress("")
  }
  const handleNewBillingAddress = () => {
    setIsShowBillingForm(true)
    setIsAddNewBilling(true)
    setBillingAddress({
      city: "",
      country: "IN",
      countryArea: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      streetAddress1: "",
      streetAddress2: "",
      phone: "",
    })
    setIsBillingSame(false)
    setToggleSelectedBillingAddress("")
  }

  return (
    <section className="checkout">
      <div className="checkout__container">
        <div className="checkout__container__address">
          {!isShowShippingForm && (
            <div className="checkout__container__address__address-list">
              <div className="checkout__container__address__address-list__title">
                Select Shipping Address
              </div>
              <div className="checkout__container__address__address-list__card-wrapper">
                {savedAddressList?.length > 0
                  ? savedAddressList.map((indAdd) => (
                      <div
                        key={indAdd.id}
                        onClick={() => fillShippingForm(indAdd)}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") fillShippingForm(indAdd)
                        }}
                        role="button"
                        className={`checkout__container__address__address-list__card-wrapper__card ${
                          indAdd.id === toggleSelectedShippingAddress &&
                          "checkout__container__address__address-list__card-wrapper__active-card"
                        }`}
                      >
                        {indAdd.firstName} {indAdd.lastName},{" "}
                        {indAdd.streetAddress1} {indAdd.streetAddress2}{" "}
                        {indAdd.city} {indAdd.postalCode} {indAdd.countryArea}{" "}
                        {indAdd.phone}
                      </div>
                    ))
                  : null}
                {savedAddressList?.length > 0 ? (
                  <div
                    className="checkout__container__address__address-list__card-wrapper__add"
                    onClick={handleNewShippingAddress}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleNewShippingAddress()
                    }}
                    role="button"
                  >
                    <Plus strokeWidth={2} size={24} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {isShowShippingForm && (
            <>
              <div className="checkout__container__address__toggle-form">
                <button
                  className={`checkout__container__address__toggle-form__button ${
                    isBtnDisabled &&
                    "checkout__container__address__toggle-form__button__disabled"
                  }`}
                  onClick={() => {
                    setIsShowShippingForm(false)
                    setIsAddNewShipping(false)
                  }}
                  disabled={isBtnDisabled}
                  title={isBtnDisabled && "There is no saved address"}
                >
                  Select from Saved Address &nbsp;
                  <Cloud strokeWidth={2} size={20} />
                </button>
              </div>
              <AddressCard
                address={shippingAddress}
                handleChange={handleShippingChange}
                type={SHIPPING_ADDRESS}
              />
            </>
          )}
          {!isShowBillingForm && (
            <div className="checkout__container__address__address-list">
              <div className="checkout__container__address__address-list__title">
                Select Billing Address
              </div>
              <div className="checkout__container__address__address-list__card-wrapper">
                {savedAddressList?.length > 0
                  ? savedAddressList.map((indAdd) => (
                      <div
                        key={indAdd.id}
                        onClick={() => fillBillingForm(indAdd)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") fillBillingForm(indAdd)
                        }}
                        tabIndex={0}
                        role="button"
                        className={`checkout__container__address__address-list__card-wrapper__card ${
                          indAdd.id === toggleSelectedBillingAddress &&
                          "checkout__container__address__address-list__card-wrapper__active-card"
                        }`}
                      >
                        {indAdd.firstName} {indAdd.lastName},{" "}
                        {indAdd.streetAddress1} {indAdd.streetAddress2}{" "}
                        {indAdd.city} {indAdd.postalCode} {indAdd.countryArea}{" "}
                        {indAdd.phone}
                      </div>
                    ))
                  : null}
                {savedAddressList?.length > 0 ? (
                  <div
                    className="checkout__container__address__address-list__card-wrapper__add"
                    onClick={handleNewBillingAddress}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleNewBillingAddress()
                    }}
                    role="button"
                  >
                    <Plus strokeWidth={2} size={24} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {isShowBillingForm && (
            <>
              <div className="checkout__container__address__toggle-form">
                <button
                  className={`checkout__container__address__toggle-form__button ${
                    isBtnDisabled &&
                    "checkout__container__address__toggle-form__button__disabled"
                  }`}
                  onClick={() => {
                    setIsShowBillingForm(false)
                    setIsAddNewBilling(false)
                  }}
                  disabled={isBtnDisabled}
                  title={isBtnDisabled && "There is no saved address"}
                >
                  Select from Saved Address &nbsp;
                  <Cloud strokeWidth={2} size={20} />
                </button>
              </div>
              <AddressCard
                shippingAddress={shippingAddress}
                setBillingAddress={setBillingAddress}
                address={billingAddress}
                handleChange={handleBillingChange}
                type={BILLING_ADDRESS}
                setIsBillingSame={setIsBillingSame}
                isBillingSame={isBillingSame}
                style={{ marginBottom: "0px" }}
              />
            </>
          )}
        </div>
        <div className="checkout__container__details">
          <Button color="primary" isBlock onClick={handleClick}>
            Proceed to Shipping
          </Button>
        </div>
      </div>
    </section>
  )
}

Checkout.propTypes = {
  lines: PropTypes.array.isRequired,
}

export default Checkout
