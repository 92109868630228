export const getLines = (lines) => {
  const tempLines = []
  if (lines) {
    for (let i = 0; i < lines.length; i++) {
      const tempLine = {}
      tempLine.quantity = lines[i].quantity
      tempLine.variantId = lines[i].variantId
      tempLines.push(tempLine)
    }
  }
  return tempLines
}

export const getValidCategoryProducts = (products) => {
  const validCategoryProducts = products.edges.filter(
    (product) => product.node.variants.length > 0
  )
  return { edges: validCategoryProducts }
}
