import React from "react"

import PropTypes from "prop-types"

import { BILLING_ADDRESS } from "../../constants/general-constants"
import { indianStates } from "../../constants/IndianStates"
import Input from "../common/Input"
import Select from "../common/Select"
import { TYPE_ERROR, toastMessage } from "../common/Toast"

import "./AddressCard.scss"

const AddressCard = ({
  type,
  address,
  handleChange,
  setIsBillingSame,
  isBillingSame,
  setBillingAddress,
  shippingAddress,
  ...rest
}) => {
  const handleCheckboxChange = (e) => {
    if (
      (shippingAddress.city === "" ||
        shippingAddress.country === "" ||
        shippingAddress.firstName === "" ||
        shippingAddress.lastName === "" ||
        shippingAddress.streetAddress1 === "" ||
        shippingAddress.countryArea === "" ||
        shippingAddress.postalCode === "") &&
      e.target.checked
    ) {
      toastMessage(
        "Please fill all the shipping address details first!",
        TYPE_ERROR
      )
    } else {
      setIsBillingSame(e.target.checked)
      !isBillingSame
        ? setBillingAddress(shippingAddress)
        : setBillingAddress({
            city: "",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
            phone: "",
          })
    }
  }

  const handleKeyDown = (e) =>
    (e.code === "Period" ||
      e.code === "KeyE" ||
      e.code === "Minus" ||
      e.code === "Equal" ||
      e.code === "NumpadSubtract" ||
      e.code === "NumpadAdd") &&
    e.preventDefault()

  return (
    <section className="address-card" {...rest}>
      <div className="address-card__title">
        {type}
        {type === BILLING_ADDRESS ? (
          <div className="address-card__title__choice">
            <Input
              id="isBillingSame"
              type="checkbox"
              name="isBillingSame"
              label="Same as shipping address?"
              checked={isBillingSame}
              onChange={handleCheckboxChange}
            />
          </div>
        ) : null}
      </div>
      <div className="address-card__input-section">
        <div className="address-card__input-section__input-group">
          <Input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={address.firstName}
            onChange={handleChange}
            style={{ marginRight: 10 }}
            isBlock
            isRequired
          />
          <Input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={address.lastName}
            onChange={handleChange}
            isBlock
            isRequired
          />
        </div>
        <div className="address-card__input-section__input-group">
          <Input
            type="text"
            placeholder="Street Address 1"
            name="streetAddress1"
            value={address.streetAddress1}
            onChange={handleChange}
            style={{ marginRight: 10 }}
            isBlock
            isRequired
          />
          <Input
            type="text"
            placeholder="Street Address 2"
            name="streetAddress2"
            value={address.streetAddress2}
            onChange={handleChange}
            isBlock
            isRequired
          />
        </div>
        <div className="address-card__input-section__input-group">
          <Select
            id="stateSelect"
            name="countryArea"
            value={address.countryArea}
            onChange={handleChange}
            style={{ marginRight: 10 }}
            options={indianStates}
            isBlock
            isRequired
          />
          <Input
            type="text"
            placeholder="City"
            name="city"
            value={address.city}
            onChange={handleChange}
            style={{ marginRight: 10 }}
            isBlock
            isRequired
          />
          <Input
            type="text"
            placeholder="Postal Code"
            name="postalCode"
            value={address.postalCode}
            onChange={handleChange}
            isBlock
            isRequired
          />
        </div>
        <div className="address-card__input-section__input-group">
          <Input
            type="number"
            placeholder="Phone Number"
            name="phone"
            value={address.phone}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            isBlock
            isRequired
          />
        </div>
      </div>
    </section>
  )
}

AddressCard.propTypes = {
  address: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setIsBillingSame: PropTypes.func,
  isBillingSame: PropTypes.bool,
  shippingAddress: PropTypes.object.isRequired,
  setBillingAddress: PropTypes.func.isRequired,
}

AddressCard.defaultProps = {
  address: {},
}

export default AddressCard
