import React from "react"

import { navigate } from "gatsby"

import { Air } from "akar-icons"

import Checkout from "../components/checkout/Checkout"
import SEO from "../components/seo"
import { CartContext } from "../store"

const CheckoutPage = () => {
  const { lines } = React.useContext(CartContext)

  React.useEffect(() => {
    if (!localStorage.token) {
      navigate("/login")
    }

    if (lines.length === 0) {
      navigate("/")
    }
  }, [])

  return (
    <>
      <SEO title="Checkout" path="/checkout" />
      {lines.length ? (
        <Checkout lines={lines} />
      ) : (
        <div className="no-items">
          <Air style={{ margin: "auto" }} size={32} />
          <br />
          <p>No Products</p>
        </div>
      )}
    </>
  )
}

export default CheckoutPage
